import React from 'react'
import HeaderBar from "./components/HeaderBar";
import WorkFeature from "./components/WorkFeature";


function Work(){
	return(
		<div>
			<HeaderBar />
			<WorkFeature />
		</div>
	)
}

export default Work;