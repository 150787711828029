import React from 'react'
import HeaderBar from "./components/HeaderBar";
import AboutFeature from "./components/AboutFeature";

function About(){
	return(
		<div>
            <HeaderBar />
            <AboutFeature />
		</div>
	)
}

export default About;